import request from "@/utils/request"
//查询商联俱乐部会员申请列表
export function membershipList(query) {
  return request({
    url: "/membership/list",
    method: "get",
    params: query
  })
}

//入会信息文件附件下载
export function downLoadFile(query) {
  return request({
    url: "/upload/downloadFileTwo",
    method: "get",
    responseType: "blob",
    params: query
  })
}

//修改商联俱乐部会员申请
export function membershipEdit(data) {
  return request({
    url: "/membership/edit",
    method: "post",
    data
  })
}


//获取商联俱乐部会员申请详细信息
export function membershipDeatile(query) {
  return request({
    url: "/membership",
    method: "get",
    params: query
  })
}

// 批量导出商联俱乐部会员申请数据
export function exportExcel(data) {
  return request({
    url: "/membership/exportExcel",
    method: "post",
    responseType: "blob",
    data
  })
}

<template>
  <div>
    <el-card>
      <div class="user">
        <img :src="dataPage.headImgUrl" alt="" />
        <span>{{ dataPage.realName }}</span>
      </div>
      <div class="titlte">企业信息</div>
      <div class="table_item">
        <div class="table_item_left">
          <ul>
            <li>
              <span>企业名称</span>
              <div class="color1">{{ dataPage.companyName ? dataPage.companyName : str }}</div>
            </li>
            <li>
              <span>所属行业领域</span>
              <div v-if="dataPage.industryTag" class="row">
                <div v-for="(item, index) in domainList" :key="index" class="row_item tag_box">
                  <!-- 展示3个标签 -->
                  <el-tooltip effect="dark" placement="top">
                    <div slot="content">
                      {{ item }}
                    </div>
                    <el-tag effect="plain" v-if="index < 4">
                      {{ item }}
                    </el-tag>
                  </el-tooltip>
                </div>
                <!-- 展示更多标签 -->
                <el-tooltip effect="light" placement="right">
                  <div slot="content" class="show_more_tag">
                    <div class="row">
                      <el-tag effect="plain" v-for="(item, index) in tagList" :key="index">
                        {{ item }}
                      </el-tag>
                    </div>
                  </div>
                  <el-tag effect="plain" v-if="tagList.length > 0">
                    {{ tagList.length + '+' }}
                  </el-tag>
                </el-tooltip>
              </div>
              <div v-else>{{ str }}</div>
            </li>
            <li>
              <span>统一社会信用代码</span>
              <div class="color1">
                {{ dataPage.unifiedSocialCreditCode ? dataPage.unifiedSocialCreditCode : str }}
              </div>
            </li>
            <li>
              <span>总资产</span>
              <div class="color1">
                <span>{{ dataPage.totalAssets ? dataPage.totalAssets : str }}</span>
                <span class="right_txt">万元</span>
              </div>
            </li>
            <li>
              <span>纳税额</span>
              <div class="color1">
                <span>{{ dataPage.ratal ? dataPage.ratal : str }}</span>
                <span class="right_txt">万元</span>
              </div>
            </li>
            <li>
              <span>职工人数</span>
              <div class="color1">
                <span>{{ dataPage.peopleNumber ? dataPage.peopleNumber : str }}</span>
                <span class="right_txt">人</span>
              </div>
            </li>
            <li>
              <span>主营业务</span>
              <div class="color1">
                {{ dataPage.mainBusiness ? dataPage.mainBusiness : str }}
              </div>
            </li>
            <li>
              <span>业务下游</span>
              <div class="color1">
                {{ dataPage.businessDownstream ? dataPage.businessDownstream : str }}
              </div>
            </li>
          </ul>
        </div>
        <div class="table_item_right">
          <ul>
            <li>
              <span>企业英文名称</span>
              <div class="color1">
                {{ dataPage.companyEnglishName ? dataPage.companyEnglishName : str }}
              </div>
            </li>
            <li>
              <span>成立时间</span>
              <div class="color1">
                {{ dataPage.establishmentDate ? dataPage.establishmentDate : str }}
              </div>
            </li>
            <li>
              <span>注册资本</span>
              <div class="color1">
                <span>{{ dataPage.registeredCapital ? dataPage.registeredCapital : str }}</span>
                <span class="right_txt">万元</span>
              </div>
            </li>
            <li>
              <span>营业额</span>
              <div class="color1">
                <span>{{ dataPage.turnover ? dataPage.turnover : str }}</span>
                <span class="right_txt">年/万元</span>
              </div>
            </li>
            <li>
              <span>注册地址</span>
              <div class="color1">
                {{ dataPage.registeredAddress ? dataPage.registeredAddress : str }}
              </div>
            </li>
            <li>
              <span>公司网站</span>
              <div class="color1">
                {{ dataPage.officialWebsite ? dataPage.officialWebsite : str }}
              </div>
            </li>
            <li>
              <span>是否上市</span>
              <div class="color1">
                <span>{{ dataPage.isShip === '1' ? '是' : '否' }}</span>
                <span v-if="dataPage.isShip === '1'">
                  <span>{{ '(' + dataPage.marketTime + ')' }}</span>
                  <span>{{ '(' + dataPage.stockCode + ')' }}</span>
                </span>
              </div>
            </li>
            <li>
              <span>业务上游</span>
              <div class="color1">
                {{ dataPage.businessUpstream ? dataPage.businessUpstream : str }}
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="titlte">个人信息</div>
      <div class="table_item">
        <div class="table_item_left">
          <ul>
            <li>
              <span>真实姓名</span>
              <div>
                <span class="color1">{{ dataPage.realName ? dataPage.realName : str }}</span>
                <i v-if="dataPage.sex == '1'" class="el-icon-male" style="color: #1296db"></i
                ><i class="el-icon-female" v-else style="color: #fd6161"></i>
              </div>
            </li>
            <li>
              <span>身份证号码</span>
              <div class="color1">
                {{ dataPage.identityCard ? dataPage.identityCard : str }}
              </div>
            </li>
            <li>
              <span>民族</span>
              <div class="color1">
                {{ dataPage.nation ? dataPage.nation : str }}
              </div>
            </li>
            <li>
              <span>籍贯</span>
              <div class="color1">
                {{ dataPage.nativePlace ? dataPage.nativePlace : str }}
              </div>
            </li>
            <li>
              <span>最高学历</span>
              <div class="color1">
                {{
                  dataPage.officialAcademicCredentials ? dataPage.officialAcademicCredentials : str
                }}
              </div>
            </li>
            <li>
              <span>专业</span>
              <div class="color1">
                {{ dataPage.specialty ? dataPage.specialty : str }}
              </div>
            </li>
            <li>
              <span>详细住址</span>
              <div class="color1">
                <span>
                  {{
                    dataPage.provincesAndMunicipalities ? dataPage.provincesAndMunicipalities : str
                  }}
                </span>
                <span v-if="dataPage.contactAddress">
                  {{ '(' + dataPage.contactAddress + ')' }}
                </span>
              </div>
            </li>

            <li>
              <span>加入的商协会</span>
              <div class="color1">
                {{ dataPage.associationPosition ? dataPage.associationPosition : str }}
              </div>
            </li>
          </ul>
        </div>
        <div class="table_item_right">
          <ul>
            <li>
              <span>职务</span>
              <div class="color1">{{ dataPage.job ? dataPage.job : str }}</div>
            </li>
            <li>
              <span>手机号码</span>
              <div class="color1">
                {{ dataPage.phone ? dataPage.phone : str }}
              </div>
            </li>
            <li>
              <span>出生年月日</span>
              <div class="color1">
                {{ dataPage.birthDate ? dataPage.birthDate : str }}
              </div>
            </li>
            <li>
              <span>政治面貌</span>
              <div class="color1">
                {{ dataPage.politicsStatus ? dataPage.politicsStatus : str }}
              </div>
            </li>
            <li>
              <span>毕业院校</span>
              <div class="color1">
                {{ dataPage.graduateInstitutions ? dataPage.graduateInstitutions : str }}
              </div>
            </li>
            <li>
              <span>业余爱好</span>
              <div class="color1">
                {{ dataPage.avocation ? dataPage.avocation : str }}
              </div>
            </li>
            <li>
              <span>家庭住址</span>
              <div class="color1">
                {{ dataPage.homeAddress ? dataPage.homeAddress : str }}
              </div>
            </li>
            <li>
              <span>个人简介</span>
              <div class="color1">
                {{ dataPage.individualResume ? dataPage.individualResume : str }}
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="titlte">联络人信息</div>
      <div class="table_item">
        <div class="table_item_left">
          <ul>
            <li>
              <span>真实姓名</span>
              <div>
                <span class="color1">
                  {{ dataPage.liaisonsRealName ? dataPage.liaisonsRealName : str }}
                </span>
                <i v-if="dataPage.liaisonsSex == '1'" class="el-icon-male" style="color: #1296db" />
                <i
                  class="el-icon-female"
                  v-else-if="dataPage.liaisonsSex == '2'"
                  style="color: #fd6161"
                />
              </div>
            </li>
            <li>
              <span>邮箱</span>
              <div class="color1">
                {{ dataPage.liaisonsMail ? dataPage.liaisonsMail : str }}
              </div>
            </li>
          </ul>
        </div>
        <div class="table_item_right">
          <ul>
            <li>
              <span>职务</span>
              <div class="color1">
                {{ dataPage.liaisonsJob ? dataPage.liaisonsJob : str }}
              </div>
            </li>
            <li>
              <span>手机号码</span>
              <div class="color1">
                {{ dataPage.liaisonsPhone ? dataPage.liaisonsPhone : str }}
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="titlte">申请信息</div>
      <div class="table_item">
        <div class="table_item_left">
          <ul>
            <li>
              <span>申请职务</span>
              <div class="color1">
                {{ dataPage.applyJob ? dataPage.applyJob : str }}
              </div>
            </li>
            <!-- <li>
              <span>入会诉求</span>
              <div class="color1">{{ dataPage.membershipAppeal }}</div>
            </li> -->
            <!-- <li>
              <span>申请单位电子章</span>
              <div>
                <img :src="dataPage.unitElectronicStamp" alt="" />
              </div>
            </li> -->
          </ul>
        </div>
        <div class="table_item_right">
          <ul>
            <li>
              <span>推荐人</span>
              <div class="color1">
                {{ dataPage.referrer ? dataPage.referrer : str }}
              </div>
            </li>
            <li>
              <span>推荐建议</span>
              <div class="color1">
                {{ dataPage.recommend ? dataPage.recommend : str }}
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="bottom" v-if="type === 0">
        <el-button type="warning" @click="passClick('2')">不通过</el-button>
        <el-button type="primary" @click="passClick('1')">通过</el-button>
      </div>
    </el-card>

    <el-dialog title="不通过" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
      <div>
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px">
          <el-form-item label="理由" prop="refusalReasons">
            <el-input
              type="textarea"
              autosize
              placeholder="请输入不通过理由"
              v-model="ruleForm.refusalReasons"
            />
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitClick">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
const defaultRuleForm = Object.freeze({
  id: '',
  membershipStatus: '',
  phone: '',
  refusalReasons: ''
})
import { membershipDeatile, membershipEdit } from '@/api/review'
export default {
  name: 'Detailed',
  created() {
    this.getDetailed() //获取详情数据
  },
  data() {
    return {
      str: '——', // 没有数据的item
      tagList: [], // 分割出的行业标签
      domainList: [], // 行业标签
      rules: {
        refusalReasons: [{ required: true, message: '请输入活动名称', trigger: 'blur' }]
      },
      ruleForm: { ...defaultRuleForm },
      dialogVisible: false, //填写拒绝理由的弹窗
      dataPage: {} //详情的数据
    }
  },
  computed: {
    id() {
      return this.$route.query.id * 1
    },
    //判断通过不通过按钮  0就是有按钮
    type() {
      return this.$route.query.type * 1
    }
  },
  methods: {
    //不通过点击确定
    submitClick() {
      const query = {
        id: this.id,
        membershipStatus: '2',
        phone: this.dataPage.phone,
        refusalReasons: this.ruleForm.refusalReasons
      }
      membershipEdit(query).then((res) => {
        if (res.data.resultCode === 200) {
          this.$message.success('审核不通过。')
          this.ruleForm = { ...defaultRuleForm }
          this.dialogVisible = false
          this.$router.go(-1)
        } else {
          this.$message.error('网络繁忙，请稍候重试。')
        }
      })
    },
    //关闭不通过的弹窗
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then((_) => {
          this.ruleForm = { ...defaultRuleForm }
          done()
        })
        .catch((_) => {})
    },
    //点击通过不通过
    passClick(type) {
      //type = 1通过  type = 2 不通过
      if (type == '1') {
        //点击通过
        const query = {
          id: this.id,
          membershipStatus: '1',
          phone: this.dataPage.phone,
          refusalReasons: ''
        }
        membershipEdit(query).then((res) => {
          if (res.data.resultCode === 200) {
            this.$message.success('审核通过。')
            this.$router.go(-1)
          } else {
            this.$message.error('网络繁忙，请稍候重试。')
          }
        })
      } else {
        this.dialogVisible = true
      }
    },
    //获取详情的数据
    async getDetailed() {
      const { data: res } = await membershipDeatile({ id: this.id })
      console.log(res.data)
      if (res.resultCode === 200) {
        this.dataPage = res.data
        // 获取行业回显
        if (res.data.industryTag) {
          const industryTag = JSON.parse(res.data.industryTag)
          industryTag.forEach((el) => {
            el.areas.forEach((i1, i2) => {
              if (i2 !== 0) {
                // areas第一个值为父级标签
                this.domainList.push(i1)
              }
            })
          })
          if (this.domainList.length > 4) {
            this.tagList = this.domainList.splice(4)
          }
        }
        // 暂时不展示一级标签
        // if (res.data.industryTag) {
        //   const jsonObj = JSON.parse(res.data.industryTag)
        //   let array = []
        //   this.dataPage.industryTag = jsonObj
        //   let industryTag = jsonObj
        //   industryTag.forEach((el) => {
        //     array = el.areas
        //   })
        //   this.domainList = array.map((el) => el)
        //   if (this.domainList.length > 4) {
        //     this.tagList = this.domainList.splice(4)
        //   }
        // }
      }
    }
  }
}
</script>
<style lang="less">
.show_more_tag {
  width: fit-content;
  display: flex;
  flex-flow: row wrap;
  max-width: 300px;
  .row {
    padding-top: 6px;
    .el-tag {
      margin-right: 6px;
      margin-bottom: 6px;
    }
  }
}
</style>
<style lang="less" scoped>
.right_txt {
  margin-left: 160px;
}
li > span {
  display: inline-block;
  width: 140px;
  margin-right: 20px;
}
.row {
  width: 100%;
  .row_item {
    width: 80px;
  }
  .tag_box {
    max-width: 120px;
    text-align: center;
    margin-right: 10px;
    overflow: hidden;
    ::v-deep .el-tag {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
.color1 {
  color: #333333 !important;
}
.user {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 40px 0;

  img {
    width: 70px;
    height: 70px;
    border-radius: 4px;
    margin-bottom: 10px;
  }

  span {
    color: #333333;
    font-size: 20px;
    font-weight: bold;
  }
}

.titlte {
  width: 100%;
  color: #4e93fb;
  font-size: 20px;
  font-weight: bold;
  overflow: hidden;
}

.table_item {
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  margin-top: 20px;

  div {
    width: 50%;
    overflow: hidden;
  }

  ul {
    width: 100%;
    overflow: hidden;
    box-sizing: border-box;

    li {
      width: 100%;
      overflow: hidden;
      margin-bottom: 16px;
      display: flex;
      flex-direction: row;
      white-space: nowrap;
      text-overflow: ellipsis;
      align-items: center;
      box-sizing: border-box;
      padding: 0 12px 0 0;
      .el-tag {
        font-size: 14px;
        color: #4e93fb;
        width: fit-content;
      }
      span {
        color: #7c7a7a;
        font-size: 16px;
        font-weight: 400;
        margin-right: 10px;
      }
      img {
        width: 145px;
        height: 145px;
      }
    }
  }
}
</style>
